var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"olimp-admin-auth",class:_vm.$style.wrapperLog},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[(_vm.show)?_c('div',{staticClass:"card",class:_vm.$style.containerLog},[_c('div',{class:_vm.$style.title},[_vm._v(" Log in to your account ")]),_c('div',[_c('a-radio-group',{attrs:{"value":_vm.settings.authProvider},on:{"change":e => _vm.changeAuthProvider(e.target.value)}},[_c('a-radio',{attrs:{"value":"jwt","hidden":""}},[_vm._v("JWT")])],1)],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {rules: [{ required: true, message: 'Please input your username!' }]}]),expression:"['email', {rules: [{ required: true, message: 'Please input your username!' }]}]"}],attrs:{"size":"large","placeholder":"Email"}},[_c('a-icon',{staticClass:"text-primary",attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {rules: [{ required: true, message: 'Please input your Password!' }]}]),expression:"['password', {rules: [{ required: true, message: 'Please input your Password!' }]}]"}],attrs:{"size":"large","placeholder":"Password","type":"password"}},[_c('a-icon',{staticClass:"text-primary",attrs:{"slot":"prefix","type":"unlock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('div',{class:_vm.$style.checkbox,staticStyle:{"display":"none"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remember',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                },
              ]),expression:"[\n                'remember',\n                {\n                  valuePropName: 'checked',\n                  initialValue: true,\n                },\n              ]"}]},[_vm._v(" Remember me ")]),_c('span',{staticClass:"float-right"},[_c('router-link',{staticClass:"kit__utils__link",attrs:{"to":"/auth/forgot-password"}},[_vm._v("Forgot Password")])],1)],1)]),_c('a-button',{class:_vm.$style.button,attrs:{"type":"primary","htmlType":"submit","size":"large","loading":_vm.loading}},[_vm._v(" Log in ")]),(1 === 0)?_c('div',{staticClass:"text-center mt-3"},[_c('span',{staticClass:"mr-1"},[_vm._v("New to NowInvoices?")]),_c('router-link',{staticClass:"kit__utils__link",attrs:{"to":"/auth/register"}},[_vm._v("Register now")])],1):_vm._e()],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }